'use client'

import { MoonIcon, SunIcon } from '@heroicons/react/16/solid'
import { memo, useContext } from 'react'
import MenuItem from 'src/app/ui/common/Popover/MenuItem'

import { ThemeContext } from 'src/components/providers/ThemeProvider'
import { EnTheme } from 'src/enums'
import { t } from 'src/helpers/translate.helper'

export const ThemeButton = memo(function ThemeButton(props: {
  onClick?: () => void
}) {
  const { theme, toggleTheme } = useContext(ThemeContext)

  return (
    <MenuItem
      item={{
        title: t('Site theme'),
        icon:
          theme === EnTheme.DARK ? (
            <SunIcon className="w-4" />
          ) : (
            <MoonIcon className="w-4" />
          ),
        onClick: () => {
          props.onClick?.()
          toggleTheme()
        },
        isActive: false,
        subTitle: (
          <span className="grid justify-end">
            {t(theme === EnTheme.DARK ? 'Dark' : 'Light')}
          </span>
        ),
      }}
    />
  )
})

import { EnBookStatus } from '@prisma/client'
import { Session } from 'next-auth'
import { isRanobe } from 'src/app/lib/utils/main.util'
import { EnAppName } from 'src/enums/app-name.enum'

export const bookInactiveStatuses: EnBookStatus[] = [
  EnBookStatus.DRAFT,
  EnBookStatus.NOT_FOUND,
]

export const getCatalogUrl = () => {
  return isRanobe() ? '/ranobe' : '/manga'
}

export const getChapterUrl = (bookSlug: string, chapterId: number) => {
  return `${getCatalogUrl()}/${bookSlug}/ch${chapterId}`
}

export const getBookUrl = (bookSlug: string) => {
  return `${getCatalogUrl()}/${bookSlug}`
}

export const isUserHasSubscription = (session: Session | null) => {
  return session ? session.user.activeSubscription !== null : false
}

export const formatNumber = (num: number) => {
  if (num < 1000) {
    return num.toString()
  }

  const units = ['K', 'M', 'B', 'T']
  let unitIndex = -1
  let n = num

  while (n >= 1000) {
    n /= 1000
    unitIndex++
  }

  return `${n.toFixed(1)} ${units[unitIndex]}`
}

export const getDomainByAppName = (appName?: string) => {
  switch (appName) {
    case EnAppName.HMANGA:
      return 'https://xn--80aaalhzvfe9b4a.xn--80asehdb'

    case EnAppName.MANGA_ONLINE:
    default:
      return 'https://xn--80aai8ag.xn--80asehdb'
  }
}

import { PopoverButton } from '@headlessui/react'
import clsx from 'clsx'
import { memo } from 'react'

export const MenuButton = memo(function MenuButton(props: {
  className?: string
  isOpen: boolean
  onClick?: VoidFunction
}) {
  return (
    <PopoverButton
      id="burger-menu"
      onClick={props.onClick}
      className={clsx(
        'w-12 aspect-square hover text-secondary relative !rounded-none outline-none',
        props.className,
        {
          'dark:bg-black-600 bg-gray-200/50 text-primary': props.isOpen,
        },
      )}
    >
      <span className="sr-only">Open main menu</span>
      <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span
          aria-hidden="true"
          className={clsx(
            'block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out',
            {
              'rotate-45': props.isOpen,
              ' -translate-y-1.5': !props.isOpen,
            },
          )}
        />

        <span
          aria-hidden="true"
          className={clsx(
            'block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out',
            { 'opacity-0': props.isOpen },
          )}
        />

        <span
          aria-hidden="true"
          className={clsx(
            'block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out',
            { '-rotate-45': props.isOpen, ' translate-y-1.5': !props.isOpen },
          )}
        />
      </div>
    </PopoverButton>
  )
})

import { CheckIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import Link from 'next/link'
import { Fragment, memo } from 'react'
import { PopoverMenuItemType } from './types'

const MenuItem = memo(function MenuItem(props: {
  item: PopoverMenuItemType
  withMark?: boolean
}) {
  if (!props.item.title) {
    return <div className="h-px bg-border my-1.5" />
  }

  const content = (
    <Fragment>
      {props.withMark && props.item.isActive && (
        <span className="absolute inset-y-0 left-0 grid items-center pl-3 text-primary">
          <CheckIcon className="w-4" />
        </span>
      )}

      {props.item.icon}
      <span>{props.item.title}</span>
      {props.item.subTitle}
    </Fragment>
  )

  const className = clsx(
    'grid grid-cols-[auto_auto_1fr] w-full justify-start hover hover:bg-gray-200 font-medium hover:dark:bg-black-700 gap-x-2 py-2 px-5 items-center',
    props.item.className,
    {
      'pl-10 relative': props.withMark,
      'bg-gray-200 dark:bg-black-700 text-primary': props.item.isActive,
      'text-secondary': !props.item.isActive,
    },
  )

  if (props.item.href) {
    return (
      <Link
        href={props.item.href}
        onClick={() => props.item.onClick?.()}
        className={className}
      >
        {content}
      </Link>
    )
  }

  return (
    <button
      onClick={() => {
        props.item.onClick?.()
      }}
      className={className}
    >
      {content}
    </button>
  )
})

export default MenuItem

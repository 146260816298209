import { PopoverPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/16/solid'
import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { memo } from 'react'
import MenuItem from 'src/app/ui/common/Popover/MenuItem'
import PopoverButton from 'src/app/ui/common/PopoverButton'
import { Icon } from 'src/components/common/Icon'
import { navigationItems } from '..'
import { MenuButton } from './MenuButton'

const MenuPopover = memo(function HeaderMenu() {
  const pathname = usePathname()

  return (
    <PopoverButton
      id="menu-popover"
      getButtonContent={(open) => <MenuButton isOpen={open} />}
      className="h-full md:hidden"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
    >
      <PopoverPanel
        portal={false}
        className={clsx(
          'popover w-[80vw] max-md:h-screen fixed inset-y-0 left-0 z-10 rounded-none',
        )}
      >
        {({ close }) => (
          <div className="grid gap-y-0.5 pb-1.5 text-secondary">
            <div className="grid grid-flow-col px-5 items-center justify-between h-12">
              <Link onClick={() => close()} href="/">
                <Icon name="logo" className="" />
              </Link>

              <button
                onClick={() => close()}
                className="transform hover:rotate-180 rounded-full hover hover:text-primary hover:dark:bg-black-650 hover:bg-gray-200/50"
              >
                <XMarkIcon className="w-5" />
              </button>
            </div>
            {navigationItems.map((item, key) => (
              <MenuItem
                key={key}
                item={{
                  ...item,
                  isActive: item.href === pathname,
                  onClick: () => {
                    close()
                  },
                }}
              />
            ))}
          </div>
        )}
      </PopoverPanel>
    </PopoverButton>
  )
})

export default MenuPopover

import { PopoverButton as Button, Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment } from 'react'

const PopoverButton = (
  props: {
    children: React.ReactNode
    buttonClassName?: string
    buttonContent?: JSX.Element | string
    getButtonContent?: (open: boolean) => JSX.Element
    className?: string
    enterFrom?: string
    id: string
    enterTo?: string
    leaveFrom?: string
    leaveTo?: string
  } & (
    | { buttonContent: JSX.Element | string }
    | { getButtonContent: (open: boolean) => JSX.Element }
  ),
) => {
  const {
    enterFrom = 'translate-y-1',
    enterTo = 'translate-y-0',
    leaveFrom = 'translate-y-0',
    leaveTo = 'translate-y-1',
  } = props

  return (
    <Popover className={clsx('relative grid', props.className)}>
      {({ open }) => (
        <>
          {props.buttonContent && (
            <Button
              id={props.id}
              className={clsx(
                'cursor-pointer outline-none grid hover items-center justify-center md:hover:bg-gray-200/50 dark:md:hover:bg-black-650 md:hover:text-primary',
                props.buttonClassName,
                { 'text-primary bg-gray-200/50 dark:bg-black-650': open },
              )}
            >
              {props.buttonContent}
            </Button>
          )}
          {props.getButtonContent ? props.getButtonContent(open) : null}
          {open && (
            <div className="fixed inset-0 w-screen h-screen dark:bg-black-1000/25 bg-black-1000/10" />
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom={clsx('opacity-0', enterFrom)}
            enterTo={clsx('opacity-100', enterTo)}
            leave="transition ease-in duration-150"
            leaveFrom={clsx('opacity-100', leaveFrom)}
            leaveTo={clsx('opacity-0', leaveTo)}
          >
            {props.children}
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default PopoverButton

'use client'

import { setCookie } from 'cookies-next'
import { createContext, useEffect, useState } from 'react'
import { EnTheme } from 'src/enums'

export const ThemeContext = createContext({
  theme: EnTheme.DARK,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleTheme: () => {},
})

export default function ThemeProvider(props: {
  children: React.ReactNode
  currentTheme: EnTheme
}) {
  const [theme, setTheme] = useState(props.currentTheme)

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0]
    if (theme === EnTheme.LIGHT) {
      html.classList.remove('bg-black-900')
    } else {
      html.classList.add('bg-black-900')
    }
  }, [theme])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme: () => {
          const value = theme === EnTheme.LIGHT ? EnTheme.DARK : EnTheme.LIGHT
          setCookie('theme', value)
          document.body.classList.replace(theme, value)
          setTheme(value)
        },
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}

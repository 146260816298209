'use client'

import { createContext, useState } from 'react'
import { EnModal } from 'src/enums'

export const ModalContext = createContext<{
  activeModal: EnModal | null
  dataModal: Record<string, any> | null
  toggleModal: (value: EnModal | null, data?: Record<string, any>) => void
}>({
  activeModal: null,
  dataModal: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleModal: (value: EnModal | null, data?: Record<string, any>) => {},
})

interface IntProps {
  children: React.ReactNode
}

export default function ModalProvider({ children }: IntProps) {
  const [activeModal, setActiveModal] = useState<EnModal | null>(null)
  const [dataModal, setDataModal] = useState<Record<string, any> | null>(null)

  return (
    <ModalContext.Provider
      value={{
        activeModal,
        dataModal,
        toggleModal: (value, data) => {
          setActiveModal(value)
          if (data) {
            setDataModal(data)
          }
        },
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

import clsx from 'clsx'
import { memo, useContext } from 'react'
import { Icon } from 'src/components/common/Icon'
import { ModalContext } from 'src/components/providers/ModalProvider'
import { EnModal } from 'src/enums'
import { t } from 'src/helpers/translate.helper'

export const LogInButton = memo(function LogInButton(props: {
  className?: string
  onClick?: () => void
}) {
  const { toggleModal } = useContext(ModalContext)

  return (
    <button
      onClick={() => {
        props.onClick?.()
        toggleModal(EnModal.AUTH)
      }}
      className={clsx(
        'outline-none grid-flow-col md:justify-start justify-center gap-x-3 items-center text-white dark:text-black-1000 rounded-md bg-primary hover:!bg-opacity-90 px-2 h-10 md:h-8 grid mx-2 md:mx-0',
        props.className,
      )}
    >
      <Icon name="logIn" className="w-4" />

      {t('Sign in')}
    </button>
  )
})

import { PopoverButton as Button, PopoverPanel } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Session } from 'next-auth'
import { Fragment } from 'react'
import PopoverButton from 'src/app/ui/common/PopoverButton'
import { UserImage } from 'src/app/ui/common/UserImage'
import { getImageSrc } from 'src/helpers/main.helper'
import LoginMenu from './LoginMenu'
import UserMenu from './UserMenu'

const UserPopover = (props: {
  className?: string
  session: Session | null
  notifications: {
    count: number
    hasImportant: boolean
  }
}) => {
  return (
    <PopoverButton
      id="user-popover-button"
      getButtonContent={(open) => (
        <Button
          id="user-popover-button"
          className={clsx(
            'w-12 aspect-square relative group hover grid justify-center outline-none items-center md:hover:bg-gray-200/50 md:hover:dark:bg-black-600',
            {
              'bg-gray-200/50 dark:bg-black-600': open,
            },
          )}
        >
          {props.session && (
            <Fragment>
              <UserImage
                className="!w-7 !h-7"
                username={props.session.user.username}
                subscriptionType={
                  props.session.user.activeSubscription?.subscriptionType
                }
                subscriptionIconClassName="-top-1 -right-1.5"
                image={getImageSrc(props.session.user.image)}
                width={28}
                height={28}
              />

              {props.notifications.count > 0 && (
                <span
                  className={clsx(
                    'rounded-full md:hidden duration-200 group-hover:scale-0 text-white dark:text-black-1000 grid place-content-center top-1 right-0.5 aspect-square w-4 font-medium absolute leading-3 text-[9px]',
                    {
                      'bg-primary': !props.notifications.hasImportant,
                      'bg-danger': props.notifications.hasImportant,
                    },
                  )}
                >
                  {props.notifications.count >= 100
                    ? '99+'
                    : props.notifications.count}
                </span>
              )}
            </Fragment>
          )}

          {!props.session && <UserIcon className="w-5 text-secondary" />}
        </Button>
      )}
      className={clsx('h-full', props.className)}
      enterFrom="max-md:translate-x-full opacity-0 md:-translate-y-1"
      enterTo="max-md:translate-x-0 opacity-100 md:translate-y-0"
      leaveFrom="max-md:translate-x-0 opacity-100 md:translate-y-0"
      leaveTo="max-md:translate-x-full opacity-0 md:-translate-y-1"
    >
      <PopoverPanel
        portal={false}
        className={clsx(
          'popover md:absolute max-md:h-screen md:top-full md:mt-1 max-md:fixed right-0 max-md:inset-y-0 md:rounded-sm2 w-[80vw] md:w-[270px] z-10 rounded-none',
        )}
      >
        {({ close }) => (
          <Fragment>
            {props.session && (
              <UserMenu
                notifications={props.notifications}
                session={props.session}
                onClick={() => close()}
              />
            )}
            {!props.session && <LoginMenu onClick={() => close()} />}
          </Fragment>
        )}
      </PopoverPanel>
    </PopoverButton>
  )
}

export default UserPopover

import { LogInButton } from '../../LogInButton'
import { ThemeButton } from '../ThemeButton'

const LoginMenu = (props: { onClick: () => void }) => {
  return (
    <div className="grid gap-y-10 py-3">
      <LogInButton onClick={props.onClick} />

      <div className="border-b border-t border-border py-1.5">
        <ThemeButton />
      </div>
    </div>
  )
}

export default LoginMenu

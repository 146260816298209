import { StarIcon } from '@heroicons/react/16/solid'
import { EnPaymentSubscriptionType } from '@prisma/client'
import clsx from 'clsx'
import Image from 'next/image'
import { memo, useState } from 'react'
import { Icon } from 'src/components/common/Icon'

export const UserImage = memo(function UserImage(props: {
  className?: string
  subscriptionIconClassName?: string
  width?: number
  height?: number
  username: string
  image: string | null
  subscriptionType?: EnPaymentSubscriptionType
}) {
  const [src, setSrc] = useState<null | string>(props.image)

  const renderSubscriptionIcon = (type: EnPaymentSubscriptionType) => {
    return (
      <StarIcon
        className={clsx('w-4 absolute', props.subscriptionIconClassName, {
          'text-orange-300': type === EnPaymentSubscriptionType.BRONZE,
          'text-neutral-400': type === EnPaymentSubscriptionType.SILVER,
          'text-amber-500': type === EnPaymentSubscriptionType.GOLD,
        })}
      />
    )
  }

  if (!src) {
    return (
      <div
        className={clsx(
          'dark:bg-black-500 relative items-center justify-center grid aspect-square rounded-full [clip-path:_none_!important] bg-gray-100',
          props.className,
        )}
      >
        <Icon name="ninja" className="w-6" />

        {props.subscriptionType &&
          renderSubscriptionIcon(props.subscriptionType)}
      </div>
    )
  }

  return (
    <div className="grid items-center relative">
      <Image
        width={props.width}
        height={props.height}
        src={props.image ?? ''}
        alt={props.username}
        onError={() => {
          setSrc(null)
        }}
        unoptimized
        className={clsx(
          'rounded-full aspect-square [clip-path:_none_!important]', // clip-path fix safari bug
          props.className,
        )}
      />

      {props.subscriptionType && renderSubscriptionIcon(props.subscriptionType)}
    </div>
  )
})

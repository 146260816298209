'use client'

import {
  ListBulletIcon,
  MagnifyingGlassIcon,
  SparklesIcon,
  TrophyIcon,
} from '@heroicons/react/16/solid'
import { MoonIcon, SunIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Session } from 'next-auth'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { useContext } from 'react'
import { Icon } from 'src/components/common/Icon'
import { ThemeContext } from 'src/components/providers/ThemeProvider'
import { EnTheme } from 'src/enums'
import { getCatalogUrl } from 'src/helpers/global.helper'
import { t } from 'src/helpers/translate.helper'
import { LogInButton } from './LogInButton'
import MenuPopover from './MenuPopover'
import UserPopover from './UserPopover'

export const navigationItems = [
  {
    title: t('Catalog'),
    href: getCatalogUrl(),
    icon: <ListBulletIcon className="w-4" />,
  },
  {
    title: t('Top'),
    href: '/top',
    icon: <TrophyIcon className="w-4" />,
  },
  {
    title: t('Subscription'),
    href: '/subscription',
    icon: <SparklesIcon className="w-4" />,
  },
  {
    title: t('Search'),
    href: '/search',
    icon: <MagnifyingGlassIcon className="w-4" />,
  },
]

export default function Header(props: {
  notifications: {
    count: number
    hasImportant: boolean
  }
  session: Session | null
}) {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const pathname = usePathname()
  const router = useRouter()

  return (
    <div className="grid max-w-7xl md:px-4 items-center gap-x-6 grid-flow-col max-md:justify-between md:grid-cols-[auto_auto_1fr] justify-self-center w-full">
      <Link href="/" className="order-2 md:order-1">
        <Icon name="logo" className="" />
      </Link>
      <div className="order-1 md:order-2 grid">
        <MenuPopover />

        <div className="md:grid grid-flow-col text-secondary hidden h-12">
          {navigationItems.map((navigationItem, key) => (
            <Link
              key={key}
              href={navigationItem.href}
              className={clsx(
                'px-3 hover:text-primary text-xs grid gap-x-2 items-center justify-start grid-flow-col font-semibold mt-[3px] py-3 hover border-b-[3px] uppercase',
                {
                  'border-primary text-primary':
                    pathname === navigationItem.href,
                  'border-transparent': pathname !== navigationItem.href,
                },
              )}
            >
              {navigationItem.href === '/search' && navigationItem.icon}

              {navigationItem.title}
            </Link>
          ))}
        </div>
      </div>
      <div
        className={clsx(
          'order-3 grid h-full items-center justify-end grid-flow-col',
          {
            'gap-x-3': !props.session,
            'gap-x-0.5': props.session,
          },
        )}
      >
        {!props.session && (
          <button
            onClick={() => {
              toggleTheme()
            }}
            className={clsx(
              'hidden md:grid hover h-full cursor-pointer text-secondary items-center md:hover:text-primary md:dark:hover:bg-black-600 md:hover:bg-gray-200/50 px-2.5',
            )}
          >
            {theme === EnTheme.DARK ? (
              <SunIcon className="w-5" />
            ) : (
              <MoonIcon className="w-5" />
            )}
          </button>
        )}

        {props.session && (
          <button
            onClick={() => {
              router.push('/user/notifications')
            }}
            className={clsx(
              'hidden group relative md:grid hover h-full cursor-pointer items-center md:hover:text-primary md:dark:hover:bg-black-600 md:hover:bg-gray-200/50 px-2.5',
              {
                'text-primary bg-gray-200/50 dark:bg-black-600':
                  pathname === '/user/notifications',
                'text-secondary': pathname !== '/user/notifications',
              },
            )}
          >
            <Icon name="bell" className="w-5" />
            {props.notifications.count > 0 && (
              <span
                className={clsx(
                  'rounded-full duration-200 group-hover:scale-0 text-white dark:text-black-1000 grid place-content-center top-1 right-0.5 aspect-square w-4 font-medium absolute leading-3 text-[9px]',
                  {
                    'bg-primary': !props.notifications.hasImportant,
                    'bg-danger': props.notifications.hasImportant,
                  },
                )}
              >
                {props.notifications.count >= 100
                  ? '99+'
                  : props.notifications.count}
              </span>
            )}
          </button>
        )}

        {!props.session && <LogInButton className="max-md:hidden" />}

        <UserPopover
          className={clsx('', {
            'md:hidden': !props.session,
          })}
          notifications={props.notifications}
          session={props.session}
        />
      </div>
    </div>
  )
}

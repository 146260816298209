import {
  ArrowRightIcon,
  BookmarkIcon,
  Cog6ToothIcon,
} from '@heroicons/react/16/solid'
import clsx from 'clsx'
import { Session } from 'next-auth'
import { signOut } from 'next-auth/react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import MenuItem from 'src/app/ui/common/Popover/MenuItem'
import { PopoverMenuItemType } from 'src/app/ui/common/Popover/MenuItem/types'
import { UserImage } from 'src/app/ui/common/UserImage'
import { Icon } from 'src/components/common/Icon'
import { getImageSrc } from 'src/helpers/main.helper'
import { t } from 'src/helpers/translate.helper'
import { ThemeButton } from '../ThemeButton'

const UserMenu = (props: {
  session: Session
  notifications: {
    hasImportant: boolean
    count: number
  }
  onClick: () => void
}) => {
  const { user } = props.session
  const pathname = usePathname()

  const items: PopoverMenuItemType[] = [
    {
      title: t('Bookmarks'),
      icon: <BookmarkIcon className="w-4" />,
      href: '/user/bookmarks',
    },
    {
      title: t('Notifications'),
      icon: <Icon name="bell" className="w-4" />,
      href: '/user/notifications',
      subTitle: props.notifications.count ? (
        <div className="grid justify-end">
          <span
            className={clsx(
              'rounded-full font-medium text-white dark:text-black-1000 grid place-content-center h-5 w-5 text-[12px]',
              {
                'bg-primary': !props.notifications.hasImportant,
                'bg-danger': props.notifications.hasImportant,
              },
            )}
          >
            {props.notifications.count}
          </span>
        </div>
      ) : undefined,
    },
  ]

  const bottomItems: PopoverMenuItemType[] = [
    {
      title: t('Settings'),
      icon: <Cog6ToothIcon className="w-4" />,
      href: '/user/settings',
    },
    {
      title: t('Logout'),
      className: '!text-danger',
      icon: <Icon name="logIn" className="w-4" />,
      isActive: false,
      onClick: () => {
        signOut()
      },
    },
  ]

  return (
    <div className="grid gap-y-1.5 py-1.5">
      <Link
        onClick={() => props.onClick()}
        href="/user/profile"
        className={clsx(
          'grid hover grid-cols-[auto_1fr] hover:bg-gray-200 hover:dark:bg-black-700 text-secondary items-center py-2 gap-x-2.5 px-5',
          {
            'bg-gray-200 dark:bg-black-700': pathname === '/user/profile',
          },
        )}
      >
        <UserImage
          width={40}
          height={40}
          username={user.username}
          image={getImageSrc(user.image)}
          subscriptionType={
            props.session.user.activeSubscription?.subscriptionType
          }
          subscriptionIconClassName="-top-1 -right-1"
          className="w-10"
        />
        <div className="grid">
          <span
            className={clsx('line-clamp-1', {
              'text-primary': pathname === '/user/profile',
            })}
          >
            {user.username}
          </span>
          <span className="grid grid-flow-col text-xs gap-x-1.5 text-gray-primary items-center justify-start">
            {t('My profile')}
            <ArrowRightIcon className="w-3" />
          </span>
        </div>
      </Link>

      <div className="h-px bg-border" />

      <div className="grid">
        {items.map((item, key) => (
          <MenuItem
            key={key}
            item={{
              ...item,
              onClick: () => {
                item.onClick?.()
                props.onClick()
              },
            }}
          />
        ))}
      </div>

      <div className="h-px bg-border" />

      <ThemeButton onClick={props.onClick} />

      <div className="h-px bg-border" />

      <div className="grid">
        {bottomItems.map((item, key) => (
          <MenuItem
            key={key}
            item={{
              ...item,
              onClick: () => {
                item.onClick?.()
                props.onClick()
              },
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default UserMenu

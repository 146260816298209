import { addToast } from 'src/app/lib/utils/toast.util'
import { API } from 'src/config'
import { EnToastType } from 'src/enums'

// For select options we add params limit=all by default to get all records without pagination
export const loadSelectOptions = <T extends object>(options: {
  url: string
  params?: string
}) => {
  const params = [options.params, 'limit=all'].join('&')
  return new Promise((resolve: (value: T[]) => void, reject) => {
    fetch(
      `${process.env.NEXT_PUBLIC_ENV_DOMAIN}/${API}${options.url}?${params}`,
    )
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        const data: T[] = await response.json()

        resolve(data)
      })
      .catch((error) => {
        addToast(String(error), EnToastType.DANGER)
        reject(String(error))
      })
  })
}

export const removeEmptyProperties = (
  obj: Record<string, string | string[] | number | number[]>,
) => {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if ((obj[key] as string[]).length === 0) {
        delete obj[key]
      }
      continue
    }

    if (!obj[key] || obj[key] === '') {
      delete obj[key]
    }
  }

  return obj
}

export const processParams = <T>(
  params: string[] | string | undefined,
  mapFunc: (param: string) => T,
  validateFunc: (value: T) => boolean,
): T[] | undefined => {
  const arrayParams =
    params === undefined ? [] : Array.isArray(params) ? params : [params]
  const values = arrayParams.map(mapFunc).filter(validateFunc)

  return values.length ? values : undefined
}

export const getManifestName = () => {
  switch (process.env.NEXT_PUBLIC_ENV_APP_NAME) {
    case 'HotManga': {
      return 'hotmanga'
    }

    case 'Ranobe': {
      return 'ranobe'
    }

    case 'YManga': {
      return 'y-manga'
    }

    default:
    case 'HManga': {
      return 'h-manga'
    }
  }
}

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getImageSrc = (link: string | null) => {
  if (!link) {
    return null
  }

  if (link.includes('https://') || link.includes('http://')) {
    return link
  }
  return `${process.env.NEXT_PUBLIC_ENV_MEDIA_DOMAIN}${link}`
}
